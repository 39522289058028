import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { MediaObserver } from 'ngx-flexible-layout';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { FadeInOut, SlideUpDown, SlideUpDownBump } from '@app/animations/fade';
import { routerTransition, routerTransitionLeft } from '@app/animations/route';
import RouteGroup from '@app/classes/routegroup';
import { I18nService } from '@app/core';

import { IdObject } from '@app/_classes/cosmos-entity';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { hasPermission } from '@app/_helpers/utils';
import { AppService } from '@app/app.service';
import { LoaderComponent } from '@app/shared';
import { WorkspaceDialogComponent } from '@app/shared/dialogs/workspace-dialog/workspace-dialog.component';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { environment } from '@env/environment';
import { initFreshChatJs, isFreshChatInitialized } from '@env/freshchat';
import { IsBetaWindow } from '@env/version';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays } from 'date-fns/esm/fp';
import { flow } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs/operators';
import {
  Logger,
  MyTimesQuery,
  UserService,
  UserSettingsQuery,
  Workspace,
  WorkspacesQuery,
  WorkspacesService,
} from 'timeghost-api';
const log = new Logger('Shell');
@UntilDestroy()
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  host: {
    class: 'shell-host',
  },
  animations: [routerTransition, routerTransitionLeft, FadeInOut, SlideUpDown, SlideUpDownBump],
})
export class ShellComponent implements OnInit, AfterContentInit {
  isLoading: boolean;
  @ViewChild('routerLoader', { static: true })
  routerLoader: LoaderComponent;
  @ViewChild('sidenav', { static: true })
  sidenav: MatSidenav;
  @ViewChild('container', { static: true })
  private _shellContainer: MatSidenavContent;
  readonly workspace$manageProjects = this.userSettingsQuery.select().pipe(
    map((x) => {
      return !!x.workspace?.permissionSettings.groupsCanManageProjects.find((y) => hasPermission(y.id, x));
    })
  );
  readonly workspace$manageHourlyRates = this.userSettingsQuery.select().pipe(
    map((x) => {
      return !!x.workspace?.permissionSettings.groupsCanSeeBillableRates.find((y) => hasPermission(y.id, x));
    })
  );
  readonly workspace$manageClients = this.userSettingsQuery.select().pipe(
    map((x) => {
      return !!x.workspace?.permissionSettings.groupsCanManageClients.find((y) => hasPermission(y.id, x));
    })
  );
  readonly workspace$admin = this.userSettingsQuery.select().pipe(
    map((x) => {
      return !!x.workspace?.users.find((y) => y.admin && y.id === x.id);
    })
  );
  readonly isSupervisor$ = this.userSettingsQuery.select().pipe(
    map((user) => {
      return !!user.workspace.users?.find((u) => u?.['supervisors']?.find((s: IdObject) => s.id === user.id));
    })
  );
  readonly user$ = this.userSettingsQuery
    .select()
    .pipe(untilDestroyed(this))
    .pipe(
      map((user) => {
        return {
          ...user,
          links: {
            // partner: this.languageService.instant('partners.link'),
          },
        };
      })
    );
  get production() {
    return environment.production;
  }
  get meta() {
    return environment.meta;
  }
  constructor(
    private router: Router,
    private titleService: Title,
    private i18nService: I18nService,
    private workspaceService: WorkspacesService,
    private userSettingsQuery: UserSettingsQuery,
    private media: MediaObserver,
    private dialog: MatDialog,
    private languageService: TranslateService,
    private appService: AppService,
    private userService: UserService,
    private myTimesQuery: MyTimesQuery,
    private recordToolbar: RecordToolbarService,
    private workspaceQuery: WorkspacesQuery
  ) {}
  get currentUser() {
    return this.userSettingsQuery.getValue();
  }
  get ShellContainer() {
    return this._shellContainer.getElementRef();
  }
  get isTeams() {
    return this.appService.isTeams();
  }

  readonly view$ = this.appService.selectedTimerView$;
  toggleNav() {
    return this.sidenav.toggle();
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.state;
  }
  isWorkspaceAdmin(ws: Workspace) {
    return ws.users.findIndex((x) => x.admin && x.id === this.currentUser.id) !== -1;
  }
  ngAfterContentInit() {
    this.isLoading = false;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentDate() {
    return new Date();
  }

  getState(outlet: any) {
    return outlet.activatedRouteData.title || outlet.activatedRouteData.state;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get isMobile(): boolean {
    // return false;
    return this.media.isActive('xs') || this.media.isActive('sm');
  }
  isMobile$ = this.media.asObservable().pipe(map((x) => x.findIndex((y) => ['sm', 'xs'].includes(y.mqAlias)) !== -1));
  get width(): number {
    return this.sidenav._getWidth();
  }
  getTranslatedDescription(routeGroup: RouteGroup) {
    return [...(routeGroup.optionalSubRouteLabels || []), ...routeGroup.routes.map((x) => x.routeText)]
      .map((x) => this.languageService.instant(x))
      .join(', ');
  }
  now() {
    return new Date();
  }
  readonly runningTime$ = this.recordToolbar.runningTime.asObservable();
  readonly comegoEnabled$ = this.appService.comegoEnabled$;

  readonly comegoOnly$ = this.appService.comegoOnly$;

  get userSettings() {
    return this.userSettingsQuery.getValue();
  }
  get workspace() {
    return this.userSettings.workspace;
  }

  readonly workspace$ = this.userSettingsQuery.select().pipe(
    distinctUntilChanged(),
    map((x) => {
      const permissions = Object.entries(x.workspace.permissionSettings).map(([key, p]) => [
        key,
        !!p.find((_p: any) => hasPermission(_p.id, x)),
      ]);
      return {
        ...x.workspace,
        hasPermission: (permission: { id: string }[]) => permission?.find((p) => hasPermission(p.id, x)),
        permissions: {
          ...permissions.reduce((l, [key, permit]: [string, boolean]) => {
            if (l[key] === undefined) l[key] = permit;
            return l;
          }, {}),
        },
      };
    })
  );
  readonly isWorkspaceOwner$ = this.workspace$.pipe(
    map((x) => x.users.findIndex((y) => y.admin && y.id === this.userSettings.id) !== -1)
  );
  readonly workspaceStatus$ = this.userSettingsQuery.select().pipe(
    distinctUntilChanged(),
    filter((x) => x.workspace && this.workspaceQuery.hasEntity(x.workspace.id)),
    map((x) => {
      return parseSubscriptionAsStatus(this.workspaceQuery.getEntity(x.workspace.id), x);
    })
  );
  readonly showUser$ = combineLatest([
    this.workspaceStatus$,
    this.media.asObservable().pipe(
      distinctUntilChanged(),
      map(() => !!this.media.isActive(['sm', 'xs']))
    ),
  ]).pipe(
    map(([ws, showMedia]) => {
      return ws.isTeams || showMedia;
    })
  );
  parseTrailLength(t: number) {
    return flow(differenceInDays(Date.now()))(t * 1000);
  }
  get isBeta() {
    return !environment.production || IsBetaWindow();
  }
  openWorkspaceDialog() {
    const ref = this.dialog.open(WorkspaceDialogComponent, {
      maxWidth: 'calc(100% - 32px)',
      closeOnNavigation: true,
      data: {
        activeWorkspace: this.workspace,
        showLoading: true,
      },
    });
    ref
      .afterClosed()
      .pipe(
        filter((x) => !!x),
        switchMap(() => {
          return this.router
            .navigateByUrl('/')
            .then(() => {
              this.appService.resetStores();
              return this.userService
                .getCurrentUser()
                .toPromise()
                .then(() => {
                  this.appService.reinitializeStores();
                  this.appService.connectSignal(true);
                  this.appService.initSignalReconnectOnFailed();
                });
            })
            .catch((err) => {
              log.error(err);
              return this.router.navigateByUrl('/not-found');
            });
        }),
        finalize(
          () => this.appService.checkLoading('fullAppLoading') && this.appService.removeLoading('fullAppLoading')
        )
      )
      .subscribe();
  }
  openWorkspacePlan() {
    this.router.navigate(['/settings/workspace/plans']);
  }
  get appVersion() {
    return environment.version;
  }

  ngOnInit() {}
  teamsLogout() {
    this.userService.teamsLogout();
  }
  openLink(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    a.remove();
  }
  get isTeamsMobile() {
    return this.isTeams && this.isMobile;
  }
  widgetLoading = false;
  openWidget() {
    if (!isFreshChatInitialized()) {
      this.widgetLoading = true;
      return initFreshChatJs(this.userSettingsQuery.getValue().id)
        .then((freshChat: any) => {
          const { officeProfile, workspace, id, email, settings } = this.userSettingsQuery.getValue();
          freshChat('identify', 'ticketForm', {
            email,
            name: `${officeProfile.givenName}${officeProfile.surname ? ' ' + officeProfile.surname : ''}`,
          });

          freshChat('open');

          this.widgetLoading = false;
        })
        .catch(() => {
          this.widgetLoading = false;
        });
    }
    window.FreshworksWidget('open');
  }
}
